import { FC } from 'react'
import rate from 'Assets/upload/images/rate.png'
import rate2x from 'Assets/upload/images/rate@2x.png'

interface Props {
  reviewLink: string
}

const WorkQualitySection: FC<Props> = ({ reviewLink }) => (
  <div
    className="section home__section home__section_no-margin"
    id="gradeBlock1"
  >
    <div className="container">
      <div className="home__block">
        <img className="home__img" src={rate} srcSet={rate2x} alt={''} />
        <h2 className="h2 home__subtitle">Качество работы</h2>
        <a className="button" id="gradeButton1" href={reviewLink}>
          Оценить
        </a>
      </div>
    </div>
  </div>
)

export default WorkQualitySection
