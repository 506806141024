import { FC } from 'react'

interface Props {
  dateCreation: string
  number: string
  address: string
  addressReceiving: string
  isPickup: boolean
  restaurantName: string
  dateReceiving: string
  payment: string
  orderItems: {
    name: string
    count: number
    sum: number
  }[]
  deliverySum: number
  total: number
}

const OrderInfoSection: FC<Props> = ({
  dateCreation,
  number,
  address,
  addressReceiving,
  isPickup,
  restaurantName,
  dateReceiving,
  payment,
  orderItems,
  deliverySum,
  total,
}) => {
  const addressType = isPickup ? 'Адрес получения' : 'Адрес доставки'
  return (
    <section className="home__section home__section_big-margin order-info-section">
      <div className="container">
        <div
          className="h2 h2 home__subtitle home__subtitle_left"
          id="orderStatus"
        >
          <div className="order">
            <div className="order__info">
              {!!dateCreation ? (
                <span>
                  Дата и&nbsp;время создания:&#32;
                  <div className="strong" id="dateCreate">
                    {dateCreation}
                  </div>
                </span>
              ) : null}
              <span>
                Номер заказа:&#32;
                <div className="strong" id="orderId">
                  {number}
                </div>
              </span>
              <span>
                {addressType}:&#32;
                <div className="strong" id="address">
                  {isPickup ? addressReceiving : address}
                </div>
              </span>
              <span>
                Ресторан:&#32;
                <div className="strong" id="restaurant">
                  {restaurantName}
                </div>
              </span>
              {!!dateReceiving ? (
                <span className="span" id="dateReceivingSpan">
                  Дата и&nbsp;время получения:&#32;
                  <div className="strong" id="dateReceiving">
                    {dateReceiving}
                  </div>
                </span>
              ) : null}
              {!!payment ? (
                <span>
                  Способ оплаты:&#32;
                  <div className="strong" id="payment">
                    {payment}
                  </div>
                </span>
              ) : null}
            </div>
            <p>Вы заказали:</p>
            <div className="order__consist" id="orderItemsBlock">
              {orderItems?.map((item, index) => (
                <div className="order__item" key={`orderItem${index}`}>
                  <span>{item.name}</span>
                  <div className="order__count">
                    <span>
                      К-во:&nbsp;
                      <span>{item.count}&nbsp;</span>порц
                    </span>
                    <span>{item.sum} ₽</span>
                  </div>
                </div>
              ))}
            </div>
            <div className="order__delivery-line">
              Доставка:&#32;
              <div className="span" id="deliveryPrice">
                {deliverySum} ₽
              </div>
            </div>
            <div className="order__total">
              <span>Сумма заказа:</span>
              <div className="span" id="sumOfOrder">
                {total} ₽
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OrderInfoSection
