import React, { useEffect, useState } from 'react'
import 'Style/index.css'
import Main from './Components/main'
import { useLocation } from 'react-router-dom'
import { OrderData } from './Types/OrderData'
import getOrderData from './Api/getOrderData'

const App = (): JSX.Element => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const id = searchParams.get('id')
  const [loading, setLoading] = useState<boolean>(true)
  const [orderData, setOrderData] = useState<OrderData>({
    number: '',
    payment: '',
    order_items: [],
    delivery_sum: 0,
    total: 0,
    restaurant_name: '',
    address: '',
    address_receiving: '',
    receipts: [],
    client_name: '',
    date_creation: '',
    date_receiving: '',
    quality_work: '',
    tips: '',
    courier_name: '',
    is_preorder: false,
    is_pickup: false,
    order_status: '',
    delivered: false,
  })
  useEffect(() => {
    if (id !== null) {
      getOrderData(id).then((data) => {
        setLoading(false)
        setOrderData(data)
      })
    }
  }, [id])
  return <>{!loading ? <Main data={orderData} /> : null}</>
}
export default App
