import { FC } from 'react'
import { OrderStatus } from 'Constants/order-status'

interface Props {
  orderStatus: OrderStatus | ''
}

const OrderStatusSection: FC<Props> = ({ orderStatus }) => {
  orderStatus = orderStatus || OrderStatus.new
  const statuses = {
    [OrderStatus.new]: 'ожидает подтверждения',
    [OrderStatus.processing]: 'в работе',
    [OrderStatus.produceWaiting]: 'готовится',
    [OrderStatus.producing]: 'готовится',
    [OrderStatus.deliveryWaiting]: 'доставляется',
    [OrderStatus.delivering]: 'доставляется',
    [OrderStatus.delivered]: 'доставлен',
    [OrderStatus.finished]: 'доставлен',
    [OrderStatus.cancelled]: 'отменен',
    [OrderStatus.rejected]: 'отменен',
  }
  return (
    <div className="order-status-block">Ваш заказ {statuses[orderStatus]}</div>
  )
}

export default OrderStatusSection
