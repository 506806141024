import { FC } from 'react'
import tips2x from 'Assets/upload/images/tips@2x.png'

interface Props {
  courierName: string
  tips: string
}

const CourierDeliveredSection: FC<Props> = ({ courierName, tips }) => (
  <section
    className="section section_yellow section_transformed section_green-border home__section home__section_negative-margin"
    id="driverBlock1"
  >
    <div className="container">
      <div className="home__block">
        <img className="home__img" src={tips} srcSet={tips2x} alt={''} />
        <div
          className="h2 h2 home__subtitle home__subtitle_contrast"
          id="driverDelivered"
        >
          Заказ доставил курьер {courierName}
        </div>
        <a className="button" id="tipsButton" href={tips}>
          Оставить чаевые
        </a>
      </div>
    </div>
  </section>
)

export default CourierDeliveredSection
