export enum OrderStatus {
  new = 'Новый',
  processing = 'Обработка',
  cancelled = 'Отменен',
  produceWaiting = 'Ожидает приготовления',
  producing = 'Готовится',
  deliveryWaiting = 'Ожидает доставки',
  delivering = 'Доставляется',
  delivered = 'Доставлен',
  rejected = 'Отменен',
  finished = 'В архиве',
}
