import 'Style/index.css'
import { OrderData } from 'Types/OrderData'
import Header from 'Components/header'
import HomeSection from 'Components/sections/home'
import WorkQualitySection from 'Components/sections/work-quality'
import ReceiptSection from 'Components/sections/receipt'
import OrderInfoSection from 'Components/sections/order-info'
import CourierDeliveringSection from 'Components/sections/courier-delivering'
import CourierDeliveredSection from 'Components/sections/courier-delivered'
import OrderStatusSection from 'Components/sections/order-status'
import { OrderStatus } from 'Constants/order-status'

interface IMainProps {
  data: OrderData
}

const Main = ({ data }: IMainProps): JSX.Element => {
  const isOrderCanceled = [
    OrderStatus.cancelled,
    OrderStatus.rejected,
  ].includes(data.order_status || OrderStatus.new)
  return (
    <div className="page">
      <Header />
      <main className="main">
        <div className="home">
          <HomeSection clientName={data.client_name} />
          {!!data.courier_name && data.delivered && !isOrderCanceled ? (
            <CourierDeliveredSection
              courierName={data.courier_name}
              tips={data.tips}
            />
          ) : null}
          {data.delivered ? (
            <WorkQualitySection reviewLink={data.quality_work} />
          ) : null}
          <OrderStatusSection orderStatus={data.order_status} />
          <OrderInfoSection
            dateCreation={data.date_creation}
            number={data.number}
            isPickup={data.is_pickup}
            address={data.address}
            addressReceiving={data.address_receiving}
            restaurantName={data.restaurant_name}
            dateReceiving={data.date_receiving}
            payment={data.payment}
            orderItems={data?.order_items}
            deliverySum={data.delivery_sum}
            total={data.total}
          />
          {!!data.courier_name && !data.delivered && !isOrderCanceled ? (
            <CourierDeliveringSection
              courierName={data.courier_name}
              orderStatus={data.order_status}
              tips={data.tips}
            />
          ) : null}
          {!data.delivered ? (
            <WorkQualitySection reviewLink={data.quality_work} />
          ) : null}
          {!isOrderCanceled ? (
            <ReceiptSection receipts={data.receipts} />
          ) : null}
        </div>
      </main>
    </div>
  )
}
export default Main
