import { FC } from 'react'
import receipt from 'Assets/upload/images/receipt.png'
import receipt2x from 'Assets/upload/images/receipt@2x.png'

interface Props {
  receipts?: string[]
}

const ReceiptSection: FC<Props> = ({ receipts }) => (
  <section className="section section_purple section_no-bottom home__section">
    <div className="container">
      <div className="home__block">
        <img className="home__img" src={receipt} srcSet={receipt2x} alt={''} />
        {receipts?.length
          ? receipts.map((item, index) => (
              <a
                className="home__link"
                href={item}
                key={`receiptsItem${index}`}
              >
                Чек &#8470;&nbsp;{index + 1}
              </a>
            ))
          : null}
        {receipts?.length ? (
          <div className="span home__text home__text_small" id="haveReceipts">
            Перейдите по&nbsp;ссылке, чтобы посмотреть чек
          </div>
        ) : (
          <div className="span home__text" id="haventReceipts">
            Здесь будет ваш фискальный чек
          </div>
        )}
      </div>
    </div>
  </section>
)

export default ReceiptSection
