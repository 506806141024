import { FC } from 'react'
import tips2x from 'Assets/upload/images/tips@2x.png'
import { OrderStatus } from 'Constants/order-status'

interface Props {
  courierName: string
  tips: string
  orderStatus: OrderStatus | ''
}

const CourierDeliveringSection: FC<Props> = ({
  courierName,
  tips,
  orderStatus,
}) => {
  const isOrderDelivered = [
    OrderStatus.finished,
    OrderStatus.delivered,
  ].includes(orderStatus || OrderStatus.new)
  return (
    <section
      className="section section_yellow section_transformed home__section"
      id="driverBlock1"
    >
      <div className="container">
        <div className="home__block">
          <img className="home__img" src={tips} srcSet={tips2x} alt={''} />
          <div
            className="h2 h2 home__subtitle home__subtitle_contrast"
            id="driverDelivered"
          >
            На&nbsp;ваш заказ назначен курьер {courierName}
          </div>
          {isOrderDelivered ? (
            <a className="button" id="tipsButton" href={tips}>
              Оставить чаевые
            </a>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default CourierDeliveringSection
